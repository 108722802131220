// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    user: null,
    token: null,
    roles: null,
    login: false,
    permissions: []
  },
  reducers: {
    handleLogin: (state, action) => {
      state.user = action.payload.user
      state.token = action.payload.token
      state.roles = action.payload.roles
      state.login = action.payload.login
      state.permissions = action.payload.permissions
    },
    handleUser: (state, action) => {
      state.user = action.payload.user
    },  
    handleLogout: state => {
      state.user = null
      state.token = null
      state.roles = null
      state.login = false
      state.permissions = []
    }
  }
})

export const { handleLogin, handleUser, handleLogout } = authSlice.actions

export default authSlice.reducer
